<template>
  <v-form autocomplete="off">
    <v-window
      v-model="model"
      class=""
    >
      <v-window-item class="pa-4">
        <v-text-field
          ref="password"
          class="py-2"
          hide-details
          label="Password"
          name="password"
          type="password"
          append-icon="mdi-login"
          outlined
          single-line
          @click:append="doConnect"
          @keydown.enter="doConnect"
        />
      </v-window-item>
    </v-window>
  </v-form>
</template>

<script>
  // Utilities
  import {
    mapActions,
  } from 'vuex'

  export default {
    name: 'FriendsLoggedOut',

    data: () => ({
      model: 0,
    }),

    watch: {
      async model (val) {
        if (val !== 1) return

        setTimeout(() => {
          this.$refs.password.focus()
        }, 300)
      },
    },

    methods: {
      ...mapActions('wallet', ['connect']),
      doConnect () {
        console.log('z', this.$router)
        this.connect(this.$router)
      },
    },
  }
</script>
